import { combineReducers, configureStore, } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistStore, persistReducer, } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authReducer, SliceName as authSliceName } from '@entities/auth';
import baseApiUnauthorized from '@app/store/api/BaseApiUnauthorized';
import baseApiAuthorized from '@app/store/api/BaseApiAuthorized';
import restApiAuthorized from '@app/store/api/restApiAuthorized';
import restApiUnauthorized from '@app/store/api/restApiUnauthorized';
import googleApi from '@app/store/api/googleApi';
import { portfolioSubscriptionReducer, PortfolioSubscriptionSliceName, portfolioEditReducer, PortfolioEditSliceName, } from '@entities/portfolio';
import { applicationReducer, SliceName as appSliceName } from '@store/slices/application';
import { exchangeReducer, SliceName as exchangeSliceName } from '@entities/exchange';
import { profileReducer, profileSliceName } from '@entities/profile';
import { userReducer, userSliceName } from '@entities/current-user';
const persistConfig = {
    key: 'app',
    version: 1,
    storage,
    blacklist: [
        baseApiUnauthorized.reducerPath,
        baseApiAuthorized.reducerPath,
        restApiAuthorized.reducerPath,
        restApiUnauthorized.reducerPath,
    ],
    whitelist: [appSliceName, profileSliceName],
};
// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
    [baseApiUnauthorized.reducerPath]: baseApiUnauthorized.reducer,
    [baseApiAuthorized.reducerPath]: baseApiAuthorized.reducer,
    [restApiAuthorized.reducerPath]: restApiAuthorized.reducer,
    [restApiUnauthorized.reducerPath]: restApiUnauthorized.reducer,
    [googleApi.reducerPath]: googleApi.reducer,
    [appSliceName]: applicationReducer,
    [authSliceName]: authReducer,
    [profileSliceName]: profileReducer,
    [userSliceName]: userReducer,
    [exchangeSliceName]: exchangeReducer,
    [PortfolioEditSliceName]: portfolioEditReducer,
    [PortfolioSubscriptionSliceName]: portfolioSubscriptionReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const setupStore = (preloadedState) => configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })
        .concat([
        baseApiAuthorized.middleware,
        baseApiUnauthorized.middleware,
        restApiAuthorized.middleware,
        restApiUnauthorized.middleware,
        googleApi.middleware,
    ]),
});
const store = setupStore();
setupListeners(store.dispatch);
const persistor = persistStore(store);
export default store;
export { persistor };
